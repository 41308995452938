import { createSlice } from "@reduxjs/toolkit";
export const rewardSlice = createSlice({
  name: "reward",
  initialState: {
    rewardList: [],
  },
  reducers: {
    setRewardList: (state, action) => {
      state.rewardList = action.payload;
    },
  },
});

export const { setRewardList } = rewardSlice.actions;
export const getReward = (state) => state.reward.rewardList;

export default rewardSlice.reducer;
