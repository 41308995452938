import { createSlice } from "@reduxjs/toolkit";
export const gameSlice = createSlice({
  name: "game",
  initialState: {
    gameProvider: [],
    gameTitle: [],
  },
  reducers: {
    setGameProvider: (state, action) => {
      state.gameProvider = action.payload;
    },
    setGameTitle: (state, action) => {
      state.gameTitle = action.payload;
    },
  },
});

export const { setGameProvider } = gameSlice.actions;
export const { setGameTitle } = gameSlice.actions;
export const getGameProvider = (state) => state.game.gameProvider;
export const getGameTitle = (state) => state.game.gameTitle;

export default gameSlice.reducer;
