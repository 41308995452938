import { createSlice } from "@reduxjs/toolkit";
export const memberSlice = createSlice({
  name: "member",
  initialState: {
    member: {},
    login: false,
    credit: 0,
    lastDeposit: null,
    loggedIn: false,
    imglogo: "",
  },
  reducers: {
    setMember: (state, action) => {
      state.login = true;
      state.member = action.payload;
    },
    setLogOut: (state, action) => {
      state.login = false;
      state.member = {};
    },
    setCredit: (state, action) => {
      state.credit = action.payload;
    },
    setloggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setLastDeposit: (state, action) => {
      state.lastDeposit = action.payload;
    },
    setimglogo: (state, action) => {
      state.imglogo = action.payload;
    },
  },
});

export const { setMember, setLogOut, setCredit, setloggedIn, setimglogo, setLastDeposit } =
  memberSlice.actions;
export const getMember = (state) => state.member.member;
export const getLogin = (state) => state.member.login;
export const getCredit = (state) => state.member.credit;
export const getLoggedIn = (state) => state.member.loggedIn;
export const getimglogo = (state) => state.member.imglogo;
export const getLastDeposit = (state) => state.member.lastDeposit;

export default memberSlice.reducer;
