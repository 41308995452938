import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import memberReducer from "./slices/member";
import systemReducer from "./slices/system";
import promotionReducer from "./slices/promotion";
import rewardReducer from "./slices/reward";
import gameReducer from "./slices/game";
import dataHuayReducer from "./slices/datahuay";

const reducers = combineReducers({
  member: memberReducer,
  system: systemReducer,
  promotion: promotionReducer,
  reward: rewardReducer,
  game: gameReducer,
  datahuay: dataHuayReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// export const resetStore = async () => {
//   await persistor.purge();
//   store.dispatch(resetStore());
//   await persistor.flush();
// };
