import React, { useState } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "src/assets/logo/logo-default.png";

export default function ModalLoading({ isOpen, imglogo }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(isOpen ?? true);
  setTimeout(() => {
    setLoading(false);
  }, 3100);
  return (
    <div>
      {imglogo ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isOpen === undefined ? false : isOpen}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} align="center">
              <img
                src={imglogo ? imglogo : logo}
                width="200px"
                alt="icon-profile"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12} align="center">
              <h4 style={{ color: "#fcfcfc" }}>{t("txt_is_loading")}....</h4>
            </Grid>
          </Grid>
        </Backdrop>
      ) : (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading ? loading : isOpen}
        >
          <div className="container_loader">
            <div className="txt_loader">
              <h1
                style={{
                  color: window.env.REACT_APP_LOADING_COLOR_TXT
                    ? window.env.REACT_APP_LOADING_COLOR_TXT
                    : "#f2d638",
                }}
              >
                {t("txt_is_loading")}....
              </h1>
            </div>
            <div className="loading">
              <div
                className="line_box"
                style={{
                  border: window.env.REACT_APP_LOADING_COLOR_BORDER
                    ? window.env.REACT_APP_LOADING_COLOR_BORDER
                    : "2px solid #ffc107",
                }}
              >
                <div
                  className="line"
                  style={{
                    color: window.env.REACT_APP_LOADING_COLOR
                      ? window.env.REACT_APP_LOADING_COLOR
                      : "#f2d638",
                    background: window.env.REACT_APP_LOADING_COLOR_BG
                      ? window.env.REACT_APP_LOADING_COLOR_BG
                      : "#f2d638",
                    filter: window.env.REACT_APP_LOADING_FILTER
                      ? window.env.REACT_APP_LOADING_FILTER
                      : "drop-shadow(0 0 10px #f2d638)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Backdrop>
      )}
    </div>
  );
}
