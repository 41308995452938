import React, { createContext, useReducer } from "react";

const lottoState = {
  listNumbers: [],
  totalBill: 0,
};

const lottoContext = createContext(lottoState);

const LottoContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(lottoReducer, lottoState);
  const setListNumbers = (payload) => {
    dispatch({ type: "SET_LIST_NUMBER", payload });
  };
  const addListNumbers = (payload) => {
    dispatch({ type: "ADD_LIST_NUMBER", payload });
  };
  const deleteListNumber = (payload) => {
    dispatch({ type: "DELETE_LIST_NUMBER", payload });
  };
  // const deletelast = (payload) => {
  //   dispatch({ type: "DELETE_LAST", payload });
  // };

  const func = {
    setListNumbers,
    addListNumbers,
    deleteListNumber,
    // deletelast,
    ...state,
  };

  return <lottoContext.Provider value={func}>{children}</lottoContext.Provider>;
};

export { lottoContext, LottoContextProvider };

export const sumTotalBill = (listNumber) => {
  // let totalBuyPrice = listNumber.reduce((total, item) => total + (item.price * item.quantity) - (item.part_buy_price_discount * item.quantity), 0).toFixed(2);
  // let totalBuyPrice = listNumber.reduce((total, item) => total + item.reduce((total, num) => total + num.price, 0), 0).toFixed(2);
  // let totalBuyPrice = listNumber
  //   .reduce((total, item) => total + item.price, 0)
  //   .toFixed(2);
  // let prItemAmount = listNumber.reduce((total, item) => total + item.quantity, 0);
  // return { totalBuyPrice };
};

const lottoReducer = (state, action) => {
  switch (action.type) {
    case "SET_LIST_NUMBER":
      return {
        ...state,
        ...sumTotalBill(action.payload),
        listNumbers: [...action.payload],
      };
    case "DELETE_LIST_NUMBER":
      let delDataIndex = state.listNumbers.findIndex(
        (item) =>
          item.huay_select === action.payload.huay_select &&
          item.number === action.payload.number
      );
      state.listNumbers.splice(delDataIndex, 1);

      return {
        ...state,
        listNumbers: [...state.listNumbers],
      };
    // case "DELETE_LAST":
    //   state.listNumbers.splice(-1);
    //   return {
    //     ...state,
    //     listNumbers: [...state.listNumbers],
    //   };
    default:
      return state;
  }
};
