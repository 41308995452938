import React, { useState } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import logo from "src/assets/logo/logo-default.png";
import { useTranslation } from "react-i18next";

export default function ModalLoadingSec({ isOpen, imglogo }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(isOpen ?? true);
  setTimeout(() => {
    setLoading(false);
  }, 3100);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading ? loading : isOpen}
      >
        <div className="container_loader">
          <div className="txt_loader">
            <h1
              style={{
                color: window.env.REACT_APP_LOADING_COLOR_TXT
                  ? window.env.REACT_APP_LOADING_COLOR_TXT
                  : "#f2d638",
              }}
            >
              {t("txt_in_progress")}....
            </h1>
          </div>
          <div className="loading">
            <div
              className="line_box"
              style={{
                border: window.env.REACT_APP_LOADING_COLOR_BORDER
                  ? window.env.REACT_APP_LOADING_COLOR_BORDER
                  : "2px solid #ffc107",
              }}
            >
              <div
                className="line"
                style={{
                  color: window.env.REACT_APP_LOADING_COLOR
                    ? window.env.REACT_APP_LOADING_COLOR
                    : "#f2d638",
                  background: window.env.REACT_APP_LOADING_COLOR_BG
                    ? window.env.REACT_APP_LOADING_COLOR_BG
                    : "#f2d638",
                  filter: window.env.REACT_APP_LOADING_FILTER
                    ? window.env.REACT_APP_LOADING_FILTER
                    : "drop-shadow(0 0 10px #f2d638)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
