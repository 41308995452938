import { createSlice } from "@reduxjs/toolkit";
export const promotionSlice = createSlice({
  name: "promotion",
  initialState: {
    promotionList:[],
  },
  reducers: {
    setPromotion: (state, action) => {
      state.promotionList = action.payload;
    },
  },
});

export const { setPromotion } = promotionSlice.actions;
export const getPromotion = (state) => state.promotion.promotionList;

export default promotionSlice.reducer;
