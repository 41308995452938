import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import th from "./locales/th.json";
import en from "./locales/en.json";
import cn from "./locales/cn.json";
import soc from "./locales/camp.json";
import lao from "./locales/lao.json";
import kr from "./locales/kr.json";
import ph from "./locales/ph.json";
import { getLocalStorage } from "./utils/utils";

i18n
  .use(Backend)
  // .use(LanguageDetector) // OPTIONAL
  .use(initReactI18next)
  .init({
    //  Set default language
    resources: {
      th: {
        translation: th,
      },
      en: {
        translation: en,
      },
      lao: {
        translation: lao,
      },
      cn: {
        translation: cn,
      },
      soc: {
        translation: soc,
      },
      kr: {
        translation: kr,
      },
      ph: {
        translation: ph,
      },
    },
    lng: getLocalStorage("lang"),
    // lng: "lo",
    fallbackLng: "th",
    debug: false,
    //  string or array of namespaces to load
    ns: "translation",
    //  default namespace used if not passed to 'translation function'
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
